import firebase from 'firebase';


var firebaseConfig = {


  apiKey: "AIzaSyAKHlHg2mPSbck2396ksojdOpoDns7ycIA",
  authDomain: "cwi-devs.firebaseapp.com",
  projectId: "cwi-devs",
  storageBucket: "cwi-devs.appspot.com",
  messagingSenderId: "1097100511343",
  appId: "1:1097100511343:web:f881510f909dc51382659a",
  measurementId: "G-XB2N7XRFZW"


  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
//   const messaging = firebase.messaging();

// messaging.getToken({vapidKey:process.env.REACT_APP_VALIDKEY});
export default firebase;
