import { useEffect, useState } from "react";
import mainicon from "../img/dev.gif";
import Aos  from 'aos';
import 'aos/dist/aos.css';
import firebase from "../firebase/base";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header=()=> {
      useEffect(()=>{
        Aos.init({duration:1000});
      },[]);


      const db = firebase.firestore()
      const [email,setemail]=useState("");

      const handleNotification=(e)=>{
        e.preventDefault();
        db.collection("Notification")
          .add({
            email: email,
          })
          .then(() => {
            toast.info('You will recieve email regarding joining cwipedia!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          })
          .catch((err) => {
            toast.error('Failed, Try Again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          });

          
        setemail("");
      };

        return(
            <>
      <div className="mainarea" >
      <div className="row gx-5 ">
        <div className="col">
          <img src={mainicon} className="img-fluid vert-move"  alt="GHRIET-DC" />
          <div className="container px-4">
            <div className="row gx-5">
              <div className="col">
                <form className="input-group input-group-lg" onSubmit={handleNotification}>
 
                <ToastContainer />
                  <input type="email" className="cform form-control " placeholder="Show your interest (Enter Your Email)" aria-describedby="button-addon2"  value={email} onChange={(e)=> setemail(e.target.value)} required/>
                  <button  className="btn btn-outline-secondary cbtn" type="submit" id="button-addon2"><i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <p className="display-6">We are a team of  <b className="green">talented</b> programmers</p>
        </div>
      </div>
    </div> 


 
            </>
        )
  
}
export default Header;