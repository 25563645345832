import React, { useEffect } from 'react'
import icon from "../img/cplogo.png";
const Nav=()=> {

    const navlink=()=>{
        setTimeout(function(){
           document.getElementById("nbtn").click();
       }, 1000);
    }
    
        return(

            <div>
               <nav className="navbar fixed-top navbar-expand-lg navbar-light shadow-lg p-3 mb-5 bg-body rounded">
                    <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={icon} className="img-fluid vert-move" alt="dev-fellow" width="30" height="24" className="d-inline-block align-text-top"/>
                         Developers
                    </a>
                        <button className="navbar-toggler" id="nbtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="https://www.cwipedia.in/">Web</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="https://play.google.com/store/apps/dev?id=5678357357226530244&hl=en_IN&gl=US" >Apps</a>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link" href="https://www.cwipedia.in/" >About</a>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                    </nav>                    
            </div>
        )

        
}
export default Nav;